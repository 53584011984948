import React from 'react';

import { Head } from '@inertiajs/react';
import route from 'ziggy-js';

import Logo from '@/Components/Logo/Logo';

type ErrorPageProps = {
  status: 403 | 404 | 500 | 503;
};

const ErrorPage = ({ status }: ErrorPageProps) => {
  const title = {
    403: 'Accès refusé ',
    404: 'Oups, vous êtes perdu(e) ?',
    500: 'Houston, nous avons un problème !',
    503: 'Service temporairement indisponible',
  }[status];

  const description = {
    403:
      "Vous essayez d'accéder à une zone protégée. Si vous pensez que c'est une erreur, contactez-nous, et nous" +
      " mènerons l'enquête !",
    404: "La page que vous cherchez n'existe pas ou plus. Retournez sur la page d'accueil et continuez votre exploration.",
    500:
      "Une erreur inattendue est survenue de notre côté. Pas d'inquiétude, nous sommes sur le coup ! En" +
      ' attendant, pourquoi ne pas prendre un café ou un cookie (les vrais, pas ceux du navigateur) ?',
    503: "Le service est momentanément indisponible. Pas d'inquiétude, nous travaillons pour rétablir les choses rapidement. Revenez dans un moment ! ",
  }[status];

  return (
    <>
      <Head title="Page not found" />

      <div className="h-screen w-full flex">
        <div className="lg:w-1/2 flex flex-col justify-between px-6 py-4">
          <header>
            <Logo width={75} />
          </header>

          <div>
            <h1 className="text-6xl font-bold text-gray-900">{title}</h1>
            <p className="text-18 leading-4 text-gray-800 pt-3">
              {description}
            </p>

            <div className="pt-6">
              <a
                className="font-semibold text-purple-500 "
                href={route('dashboard.home')}
              >
                <span aria-hidden="true" className="pr-1">
                  &larr;
                </span>
                Retour à l'accueil
              </a>
            </div>
          </div>

          <div className="h-20"></div>
        </div>

        <div className="hidden lg:block lg:w-1/2 h-full">
          <img
            alt=""
            src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
